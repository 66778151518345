import { ChangeEvent, ChangeEventHandler, ReactElement, useCallback, useMemo } from "react";
import { Box, InputLabel, MenuItem, SxProps, TextField, Theme, Typography } from "@mui/material";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";

import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";

export type InvestigateDropdownOption = {
  id?: string;
  group?: string | null;
  title: string;
  value?: string;
};

export type InvestigateDropdownProps = {
  defaultValue?: string;
  disabled?: boolean;
  disableRule?: (value?: string) => boolean;
  /* Force select/textfield to full width of container */
  fullWidth?: boolean;
  grouped?: boolean;
  id?: string;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  options: InvestigateDropdownOption[];
  placeholder?: string;
  preventGrowth?: boolean;
  sx?: SxProps<Theme>;
  title: string | ReactElement;
  type?: "Chart" | "Map";
  value?: string;
  valueIsId?: boolean;
  valueItemIsSelected?: (params: {
    value?: string;
    title?: string;
    selectedValue?: string;
  }) => boolean;
};

export const InvestigateDropdown: React.FC<InvestigateDropdownProps> = ({
  defaultValue,
  disabled = false,
  disableRule,
  fullWidth,
  grouped = false,
  id = "",
  name,
  onChange,
  options: _options,
  placeholder = "Select Option",
  sx,
  title,
  type = "Chart",
  value,
  valueIsId = false,
  valueItemIsSelected
}) => {
  const options = useMemo(() => {
    if (
      !grouped ||
      !_options.some((option) => "group" in option && typeof option.group === "string")
    )
      return _options;
    const groupedOptions: InvestigateDropdownOption[][] = [];
    sortBy(Object.entries(groupBy(_options, "group")), [0]).forEach(([groupName, groupOptions]) => {
      groupedOptions.push([
        {
          id: groupName,
          title: groupName,
          value: groupName
        },
        ...groupOptions
      ]);
    });
    return groupedOptions.flat();
  }, [_options, grouped]);

  const selectedValue = useMemo(() => {
    return value === "All" ? undefined : value;
  }, [value]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      sendGaUserInteractionEvent({
        category: `${type}s`,
        action: "Investigate Dropdown Change",
        label: event.target.value
      });
      onChange?.(event);
    },
    [onChange, type]
  );

  const textFieldId = `select-data-set-${id}`;
  const textFieldLabelId = `${textFieldId}-label`;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: { xs: "100%", lg: fullWidth ? "100%" : 300 },
        maxWidth: { xs: "100%", lg: fullWidth ? "none" : 400 },
        flex: { xs: 1, lg: 0 },
        ...sx
      }}
    >
      <InputLabel id={textFieldLabelId}>{title}</InputLabel>
      <TextField
        name={name}
        select
        value={selectedValue}
        defaultValue={defaultValue}
        onChange={handleChange}
        disabled={disabled ?? false}
        SelectProps={{
          id: textFieldId,
          labelId: textFieldLabelId,
          displayEmpty: true,
          placeholder,
          value: selectedValue === undefined ? "" : selectedValue,
          role: "menu",
          "aria-label": selectedValue,
          renderValue: (value) => {
            let _value = value as string;
            if (selectedValue === undefined || !value)
              return <Typography color="action.disabled">{placeholder}</Typography>;
            if (valueIsId) {
              _value = options.find((o) => o.value === value)?.title ?? "";
            } else {
              _value = value as string;
              _value.charAt(0).toUpperCase();
              _value.slice(1);
            }
            return <>{_value}</>;
          },
          MenuProps: {
            sx: { maxHeight: "500px" }
          }
        }}
        sx={({ palette }) => ({
          fontWeight: "bold",
          backgroundColor: palette.common.white,
          width: { xs: "100%", lg: "auto" },
          "& .MuiSelect-select": {
            color: value === defaultValue ? undefined : palette.dropdowns.main
          },
          "& [role='heading']": {
            marginTop: 2
          }
        })}
      >
        {options.map(({ title, value, group = "" }) => {
          const isSelected =
            valueItemIsSelected?.({ value, title, selectedValue }) ??
            (value === selectedValue || title === selectedValue);
          const disabled = !group && disableRule ? disableRule?.(value) : undefined;
          return (
            <MenuItem
              key={title}
              value={value}
              aria-label={title}
              selected={selectedValue !== undefined && value === selectedValue}
              disabled={disabled}
              sx={({ palette }) =>
                value === undefined
                  ? {
                      color: "action.active",
                      fontWeight: 600,
                      pointerEvents: "none",
                      fontSize: 14
                    }
                  : {
                      pl: group ? 3 : 2,
                      fontWeight: grouped && !group ? 700 : isSelected ? 600 : "inherit",
                      color: isSelected
                        ? `${palette.component.links ?? "#0060F0"} !important`
                        : palette.component.textColor,
                      backgroundColor: isSelected
                        ? "rgba(2, 136, 209, 0.035) !important"
                        : undefined
                    }
              }
            >
              <Box
                role="menuitem"
                component="span"
                sx={{
                  display: "block",
                  maxWidth: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {title}
              </Box>
            </MenuItem>
          );
        })}
      </TextField>
    </Box>
  );
};
