import isNil from "lodash/isNil";

import { MhcGeographyEnum, MhcStatIdentifier } from "graphqlApi/types";

import { calculateClippedRange } from "../../util/getClippedRange";
import {
  ColorRangeName,
  continuousColorScale,
  DEFAULT_MAP_COLOR_RANGE_NAME
} from "common/components/GeoMap/utils";
import { AddInfoBoxToPropertiesProps } from "common/components/LocationSwitcher/util/groupAndTurnLocationsIntoFeatures";

import { noDataMapColor, zeroMapColor } from "common/components/GeoMap/mapStyles";
import { MinMaxFeaturesProps } from "./updateMinMaxFromFeature";

interface GetColorBasedOnValueProps {
  geography: MhcGeographyEnum;
  minMaxByStat?: MinMaxFeaturesProps["initial"];
  stats: Record<string, MhcStatIdentifier>;
  values: Record<string, number | null>;
  allValuesByStatGeo: AddInfoBoxToPropertiesProps["allValuesByStatGeo"];
  colorRangeName?: ColorRangeName;
}

/**
 * Get the colors of a feature based on its values for each of the stat identifiers
 *
 * @param props - configuration object
 * @param props.geography - selected geography
 * @param props.minMaxByStat - object containing the min and max values for each of the stat identifiers grouped by geography
 * @param props.stats - record of the stats that should be configured
 * @param props.values - record of the values by stat identifeir
 * @param props.allValuesByStatGeo - record of all values by stat. Used to calclulate the range for the legend
 * @param props.colorRangeName - name of color range indicating the color scale to use
 *
 * @returns a record with the color for each of the stat identifiers
 */
export const getColorsBasedOnValues = ({
  geography,
  minMaxByStat,
  values,
  stats,
  allValuesByStatGeo,
  colorRangeName = DEFAULT_MAP_COLOR_RANGE_NAME
}: GetColorBasedOnValueProps): Record<string, string> | null => {
  const colors: Record<string, string> = {};
  Object.entries(minMaxByStat ?? {}).forEach(([statId, minMaxByGeo]) => {
    const investigationMinMax = minMaxByGeo ? minMaxByGeo[geography] : undefined;
    const stat = stats[statId];
    const isPercentAndMaxIs100 = stat?.isPercent && investigationMinMax?.maxValue === 100;
    const valuesByStat = allValuesByStatGeo[statId];
    const allValues = valuesByStat ? valuesByStat[geography] : undefined;
    const value = values[statId];
    const clippedRange =
      (!isPercentAndMaxIs100 && allValues?.length && calculateClippedRange(allValues)) ?? undefined;
    const clippedMaxValue = clippedRange ? clippedRange[1] : isPercentAndMaxIs100 ? 100 : undefined;
    const getColorMethod = continuousColorScale(
      [
        investigationMinMax?.minValue as number,
        clippedMaxValue ?? (investigationMinMax?.maxValue as number)
      ],
      {
        colorRangeName
      }
    );
    const noValue = isNil(value);
    if (!getColorMethod || noValue) {
      colors[statId] = noDataMapColor;
      return;
    }
    if (value === 0) {
      colors[statId] = zeroMapColor;
      return;
    }
    let color = getColorMethod(value) as string;
    if (clippedMaxValue) {
      const clippedValue = value > clippedMaxValue ? clippedMaxValue : value;
      color = getColorMethod(clippedValue) as string;
    }
    colors[statId] = color;
  });
  return colors;
};
