import isNil from "lodash/isNil";

import { MhcLocation } from "graphqlApi/types";

import {
  AddInfoBoxToPropertiesProps,
  getLastValueFromProperty
} from "common/components/LocationSwitcher/util/groupAndTurnLocationsIntoFeatures";

interface updateAllValuesByStatGeoProps {
  location: MhcLocation;
  initial: AddInfoBoxToPropertiesProps["allValuesByStatGeo"];
}

/**
 * Updates all the values by stat identifier and geography based on a new location
 * @param props - configuration object
 * @param props.location - location to use for updating object
 * @param props.initial - initial version of the allValuesByStatGeo object to be updated
 * @returns An updated version of the allValuesByStatGeo object
 */
export const updateAllValuesByStatGeo = ({
  location,
  initial
}: updateAllValuesByStatGeoProps): AddInfoBoxToPropertiesProps["allValuesByStatGeo"] => {
  const stats = location.mapData?.features.flatMap((feature) => {
    return feature.properties.stats;
  });
  let copy = { ...initial };
  stats?.forEach((stat) => {
    if (stat === undefined || stat === null) return;
    const lastValue = getLastValueFromProperty(stat)?.lastValue ?? null;
    if (!isNil(lastValue)) {
      const allValuesByGeo = copy[stat.statIdentifier.id];
      if (!isNil(allValuesByGeo)) {
        const allValues = allValuesByGeo[location.geography];
        if (!isNil(allValues)) {
          copy[stat.statIdentifier.id] = {
            ...copy[stat.statIdentifier.id],
            [location.geography]: [...allValues, lastValue]
          };
        } else {
          copy[stat.statIdentifier.id] = {
            [location.geography]: [lastValue]
          };
        }
      } else {
        copy = {
          ...copy,
          [stat.statIdentifier.id]: {
            [location.geography]: [lastValue]
          }
        };
      }
    }
  });
  return copy;
};
