import isNil from "lodash/isNil";

import { InvestigateMapProps } from "../../InvestigateMap/util/types";
import { InvestigateMapPropsV2 } from "../../InvestigateMap/V2/util/types";
import {
  MhcFeature,
  MhcFeatureCollectionFragment,
  MhcGeographyEnum,
  MhcLocation,
  MhcLocationFragment,
  MhcLocationStat,
  MhcStatIdentifier,
  MhcTimeSeriesGranularityEnum
} from "graphqlApi/types";

import { ColorRangeName, DEFAULT_MAP_COLOR_RANGE_NAME } from "../../GeoMap/utils";
import { getColorsBasedOnValues } from "../../InvestigateMap/V2/util/getColorsBasedOnValues";
import { updateAllValuesByStatGeo } from "../../InvestigateMap/V2/util/updateAllValuesByStatGeo";
import {
  MinMaxFeaturesProps,
  updateMinMaxFromFeature
} from "../../InvestigateMap/V2/util/updateMinMaxFromFeature";
import { createFeatureCollectionFromGeoJsons } from "common/components/GeoMap/util/createFeatureCollectionFromGeoJsons";
import { formatDateByGranularity, formatValueByUnit } from "common/util/formatHelpers";
import { getValueOptionsFromSi } from "common/util/formatHelpers/statIdentifierHelpers";
import { getUtcDateFromString } from "common/util/utcDateFromString";

import { GeoMapPopoverResult } from "common/components/GeoMap/Popover/GeoMapPopover";

export type NoCountry = Exclude<MhcGeographyEnum, "country">;

export interface AddInfoBoxToPropertiesProps {
  features: MhcFeature[];
  geography: MhcGeographyEnum;
  selectedLocationId?: string;
  addColor?: boolean;
  selectedSi?: string;
  minMaxByStat?: MinMaxFeaturesProps["initial"];
  allValuesByStatGeo: Record<string, Partial<Record<MhcGeographyEnum, number[]>>>;
  latestDateMap?: Record<string, string | null>;
  overrideDateByStatMap?: InvestigateMapPropsV2["overrideDateByStatMap"];
  colorRangeName?: ColorRangeName;
}

export const getLastValueFromProperty = (stat: MhcLocationStat) => {
  if (stat?.timeSeries && stat?.timeSeries?.values && stat?.timeSeries?.values.length > 0) {
    return {
      lastValue: stat.timeSeries.values[stat.timeSeries.values.length - 1] ?? null,
      lastDate: stat.timeSeries.dates[stat.timeSeries.dates.length - 1] ?? null
    };
  }
  return {
    lastValue: stat?.lastValue ?? null,
    lastDate: stat?.lastUpdatedOn
  };
};

export const formatLastDate = (
  date: string | null | undefined,
  granularity?: MhcTimeSeriesGranularityEnum | null
): string | null => {
  if (isNil(date)) return null;
  const _date = getUtcDateFromString(date);
  if (isNil(_date)) return null;
  const formattedDate = formatDateByGranularity({
    value: _date,
    granularity,
    specialFormat: "weekRange"
  });
  return formattedDate;
};

export const addInfoBoxToProperties = ({
  features,
  geography,
  selectedLocationId,
  addColor,
  minMaxByStat,
  allValuesByStatGeo,
  latestDateMap,
  overrideDateByStatMap,
  colorRangeName = DEFAULT_MAP_COLOR_RANGE_NAME
}: AddInfoBoxToPropertiesProps) => {
  return features.map((feature) => {
    const valueMap: Record<string, GeoMapPopoverResult> = {};
    const stats: Record<string, MhcStatIdentifier> = {};
    const values: Record<string, number | null> = {};
    let shouldSkip = true;
    const _statDate = (
      statId: string,
      _date: string | null,
      _lastDateString: string | null | undefined
    ) => {
      let date = _date;
      const _lastDate = getUtcDateFromString(_lastDateString);
      const {
        startsOn = null,
        endsOn = null,
        granularity = null
      } = overrideDateByStatMap?.[statId] ?? {};
      if (startsOn && endsOn) {
        date = [startsOn, endsOn].join(" - ");
      } else if (startsOn && !endsOn && date && !date.includes("-")) {
        date = [startsOn, date].join(" - ");
      } else if (startsOn && !endsOn && _lastDate) {
        const lastDate = formatDateByGranularity({
          value: _lastDate,
          granularity: granularity ?? "week"
        });
        date = [startsOn, lastDate].join(" - ");
      }
      return date;
    };
    feature.properties.stats?.forEach((stat) => {
      stats[stat.statIdentifier.id] = stat.statIdentifier;
      const { lastValue, lastDate } = getLastValueFromProperty(stat);
      const statDate = latestDateMap ? latestDateMap[stat.statIdentifier.id] : null;
      if (!isNil(statDate)) {
        const comparisonLatestDate = getUtcDateFromString(statDate);
        const comparisonLastDate = getUtcDateFromString(lastDate);
        if (
          !isNil(comparisonLatestDate) &&
          !isNil(comparisonLastDate) &&
          comparisonLastDate >= comparisonLatestDate
        ) {
          shouldSkip = false;
        }
      }
      values[stat.statIdentifier.id] = lastValue ?? null;
      valueMap[stat.statIdentifier.id] = {
        nameOfIndicator: stat.statIdentifier.name,
        result: lastValue,
        formattedResult:
          formatValueByUnit({
            value: lastValue,
            ...getValueOptionsFromSi(stat.statIdentifier)
          }) ?? "",
        date: _statDate(
          stat.statIdentifier.id,
          formatLastDate(lastDate, stat.granularity),
          lastDate
        ),
        statIdentifier: stat.statIdentifier,
        granularity: stat.granularity ?? null,
        index: 0
      };
    });
    let colorsBasedOnValue: Record<string, string> = {};
    if (addColor) {
      colorsBasedOnValue =
        getColorsBasedOnValues({
          geography,
          minMaxByStat,
          stats,
          values,
          allValuesByStatGeo,
          colorRangeName
        }) ?? {};
    }
    if (shouldSkip === true)
      return {
        ...feature,
        properties: { ...feature.properties, stats: [], colors: colorsBasedOnValue }
      };
    return {
      ...feature,
      properties: {
        ...feature.properties,
        colors: colorsBasedOnValue,
        infoBoxProps: {
          valueMap,
          // reportCallbackName: props.reportCallbackName,
          selectedLocationGeography: geography,
          locationName: feature.properties.name,
          buttonTitle:
            feature.properties.id === selectedLocationId ? "Current location" : "Go to location",
          disableCallbackButton: feature.properties.id === selectedLocationId,
          buttonHref: "#",
          pillButtonSx:
            feature.properties.id === selectedLocationId
              ? {
                  border: "1px solid var(--grey-700, #616161)",
                  "&.MuiButton-root": {
                    color: "#616161"
                  }
                }
              : null,
          onlyUpdateRouteLocation: true,
          locationId: feature.properties.id,
          width: "200px",
          allowEmpty: true
        }
      }
    };
  });
};

interface GroupAndTurnLocationsIntoFeaturesProps {
  locations: MhcLocation[];
  selectedLocationId?: string;
  onlyGeos?: MhcGeographyEnum[];
  addColor?: boolean;
  selectedSi?: string;
  initialMinMax?: MinMaxFeaturesProps["initial"];
  overrideDateByStatMap?: InvestigateMapPropsV2["overrideDateByStatMap"];
  colorRangeName?: ColorRangeName;
}

export const groupAndTurnLocationsIntoFeatures = ({
  locations,
  selectedLocationId,
  onlyGeos,
  addColor,
  selectedSi,
  initialMinMax,
  overrideDateByStatMap,
  colorRangeName
}: GroupAndTurnLocationsIntoFeaturesProps) => {
  const locationMap: Partial<Record<NoCountry, MhcLocation[]>> = {};
  const featureMap: Partial<Record<NoCountry, MhcFeatureCollectionFragment>> = {};
  const locationIdMap: Partial<Record<NoCountry, string[]>> = {};
  const locationIds: string[] = [];
  const locationReference: InvestigateMapProps["locationReference"] = {};
  const latestDateString: Record<string, string | null> = {};
  const latestDataDate: Record<string, Date | null> = {};
  let allValuesByStatGeo: AddInfoBoxToPropertiesProps["allValuesByStatGeo"] = {};
  let minMaxByStat: MinMaxFeaturesProps["initial"] = initialMinMax ?? {};
  locations?.forEach((location) => {
    if (!isNil(onlyGeos) && onlyGeos.length > 0 && !onlyGeos.includes(location.geography)) return;
    locationIds.push(location.id);
    locationReference[location.id] = location;
    const feature = location.mapData?.features.find(
      ({ properties }) => !isNil(properties.stats) && properties.stats.length > 0
    );
    if (
      location.mapData &&
      location.geography &&
      location.geography !== "country" &&
      locationMap[location.geography]
    ) {
      locationIdMap[location.geography]?.push(location.id);
      locationMap[location.geography]?.push(location);
    } else {
      locationMap[location.geography as NoCountry] = [location];
      locationIdMap[location.geography as NoCountry] = [location.id];
    }
    if (!isNil(feature)) {
      feature.properties.stats?.forEach((stat) => {
        const { lastDate } = getLastValueFromProperty(stat);
        if (lastDate) {
          const currentDate = getUtcDateFromString(lastDate);
          const statDate = latestDataDate[stat.statIdentifier.id];
          if (currentDate) {
            if (isNil(statDate)) {
              latestDataDate[stat.statIdentifier.id] = currentDate;
              latestDateString[stat.statIdentifier.id] = lastDate;
            } else if (!isNil(statDate) && currentDate > statDate) {
              latestDataDate[stat.statIdentifier.id] = currentDate;
              latestDateString[stat.statIdentifier.id] = lastDate;
            }
          }
        }
      });
    }
    minMaxByStat = updateMinMaxFromFeature({ location, initial: minMaxByStat });
    allValuesByStatGeo = updateAllValuesByStatGeo({ location, initial: allValuesByStatGeo });
  });
  Object.entries(locationMap).forEach(([geography, geoJsons]) => {
    if (geoJsons && geography !== "country") {
      // Collect geoJSON features into collection from geoJSON
      let features = createFeatureCollectionFromGeoJsons(geoJsons as MhcLocationFragment[]);
      // Add info box props
      features = addInfoBoxToProperties({
        addColor: addColor ?? false,
        allValuesByStatGeo,
        colorRangeName,
        features,
        geography: geography as MhcGeographyEnum,
        latestDateMap: latestDateString,
        minMaxByStat,
        overrideDateByStatMap,
        selectedLocationId,
        selectedSi
      });
      featureMap[geography as NoCountry] = {
        type: "FeatureCollection",
        features
      };
    }
  });
  return {
    featureMap: featureMap as Record<NoCountry, MhcFeatureCollectionFragment>,
    locationMap: locationMap,
    locationIds,
    locationIdMap,
    locationReference,
    minMaxByStat
  };
};
