import isNil from "lodash/isNil";

import { MhcGeographyEnum, MhcLocation } from "graphqlApi/types";

import { getLastValueFromProperty } from "common/components/LocationSwitcher/util/groupAndTurnLocationsIntoFeatures";

export type MinMaxValue = { minValue: number | null; maxValue: number | null };

export interface MinMaxFeaturesProps {
  initial: Record<string, Partial<Record<MhcGeographyEnum, MinMaxValue>>>;
  location: MhcLocation;
}
/**
 * Updates all the min max object by stat identifier and geography based on a new location
 *
 * @param props - configuration object
 * @param props.location - location to use for updating object
 * @param props.initial - initial version of the minMaxByStat object to be updated
 * @returns An updated version of the minMaxByStat object
 */
export const updateMinMaxFromFeature = ({
  initial,
  location
}: MinMaxFeaturesProps): MinMaxFeaturesProps["initial"] => {
  const stats = location.mapData?.features.flatMap((feature) => {
    return feature.properties.stats;
  });
  let copy = { ...initial };
  stats?.forEach((stat) => {
    if (stat === undefined || stat === null) return;
    const lastValue = getLastValueFromProperty(stat)?.lastValue ?? null;
    if (!isNil(lastValue)) {
      const statsMinMax = copy[stat.statIdentifier.id];
      if (!isNil(statsMinMax)) {
        const minMax = statsMinMax[location.geography];
        if (!isNil(minMax) && !isNil(minMax.minValue) && !isNil(minMax.maxValue)) {
          const newMinMax: MinMaxValue = {
            minValue: lastValue < minMax.minValue ? lastValue : minMax.minValue,
            maxValue: lastValue > minMax.maxValue ? lastValue : minMax.maxValue
          };
          copy[stat.statIdentifier.id] = {
            ...statsMinMax,
            [location.geography]: newMinMax
          };
        } else {
          copy[stat.statIdentifier.id] = {
            [location.geography]: {
              minValue: lastValue,
              maxValue: lastValue
            }
          };
        }
      } else {
        copy = {
          ...copy,
          [stat.statIdentifier.id]: {
            [location.geography]: {
              minValue: lastValue,
              maxValue: lastValue
            }
          }
        };
      }
    }
  });
  return copy;
};
